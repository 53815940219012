.b-button {
  // Button
  .button {
    &.icon-check,
    &.icon-email,
    &.icon-phone {
      &::before {
        @extend .fi::before;
        display: inline-block;
        margin-right: .75rem;
        font-size: 2rem;
        vertical-align: inherit;
      }
    }

    &.icon-check::before {
      content: $font-Flaticon-check;
    }

    &.icon-email::before {
      content: $font-Flaticon-email;
    }

    &.icon-phone::before {
      content: $font-Flaticon-phone;
    }
  }
}
