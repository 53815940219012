    /*
    Flaticon icon font: Flaticon
    Creation date: 26/08/2016 18:44
    */

    @font-face {
  font-family: "Flaticon";
  src: url("../fonts/flaticon/Flaticon.eot");
  src: url("../fonts/flaticon/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/flaticon/Flaticon.woff") format("woff"),
       url("../fonts/flaticon/Flaticon.ttf") format("truetype"),
       url("../fonts/flaticon/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/flaticon/Flaticon.svg#Flaticon") format("svg");
  }
}

    .fi::before {
        display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
    }

    .flaticon-arrow-bottom::before { content: "\f100"; }
.flaticon-arrow-right::before { content: "\f101"; }
.flaticon-check::before { content: "\f102"; }
.flaticon-close::before { content: "\f103"; }
.flaticon-email::before { content: "\f104"; }
.flaticon-menu::before { content: "\f105"; }
.flaticon-phone::before { content: "\f106"; }

    $font-Flaticon-arrow-bottom: "\f100";
    $font-Flaticon-arrow-right: "\f101";
    $font-Flaticon-check: "\f102";
    $font-Flaticon-close: "\f103";
    $font-Flaticon-email: "\f104";
    $font-Flaticon-menu: "\f105";
    $font-Flaticon-phone: "\f106";
