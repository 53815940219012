.c-slider {
  position: relative;
  overflow: hidden;
  z-index: 0;

  @at-root {
    .no-js & img {
      width: 100%;
    }
  }

  .slick {
    &-slide {
      outline: 0;
    }

    &-prev,
    &-next {
      @include disable-mouse-outline;
      // @include transition;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      padding: 2rem 1.5rem;
      border: 0;
      border-radius: 0;
      font-size: 3rem;
      color: $white;
      text-shadow: 0 0 .125rem transparentize($black, .5);
      z-index: 1;

      &:hover,
      &:active {
        text-shadow: none;
      }

      &:hover {
        background-color: $primary;
      }

      &:active {
        background-color: smart-scale($primary, 10%);
      }
    }

    &-prev {
      left: 3%;

      &::before {
        // @extend .fi::before;
        // content: $font-Flaticon-arrow-left;
        content: '<';
      }
    }

    &-next {
      right: 3%;

      &::before {
        // @extend .fi::before;
        // content: $font-Flaticon-arrow-right;
        content: '>';
      }
    }
  }
}
