.b-text {
  @include gutter(margin-bottom);

  &--center {
    h1, h2, h3, h4, h5, h6,
    .subheading {
      text-align: center;
    }
  }

  p {
    margin-bottom: 1em;
  }
}
