@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-everything(true);

@mixin transition($duration: .125s) {
  transition: all $duration ease;
}

@mixin gutter($property, $factor: 1, $gutter: $grid-column-gutter) {
  @each $breakpoint, $value in $gutter {
    @include breakpoint($breakpoint) {
      @if strip-unit($factor * $value) != 0 {
        #{$property}: $factor * $value;
      } @else {
        #{$property}: 0;
      }
    }
  }
}

@mixin full-width($value: 50vw) {
  margin-left: -$value !important;
  margin-right: -$value !important;
  padding-left: $value !important;
  padding-right: $value !important;
}

.full-width {
  @include full-width;
}

$group-height: 25rem;
$large-font-size: 1.25rem;
$transparent-white: transparentize($white, 1);
$transparent-black: transparentize($black, 1);

@import 'flaticon';
@import 'animate';
@import 'slick.scss';
@import 'vendors/dropdown/dropdown';
@import 'components/footer';
@import 'components/header';
@import 'components/icon';
@import 'components/pagination';
@import 'components/slider';
@import 'components/sticker';
@import 'components/zellenkur';
@import 'blocks/button';
@import 'blocks/call-to-action';
@import 'blocks/chronicle';
@import 'blocks/column';
@import 'blocks/contents-teaser';
@import 'blocks/gallery';
@import 'blocks/hero';
@import 'blocks/icon';
@import 'blocks/list';
@import 'blocks/navigation';
@import 'blocks/printing-methods-teaser';
@import 'blocks/sticker';
@import 'blocks/table';
@import 'blocks/tabs';
@import 'blocks/text';
@import 'blocks/themes-teaser';
@import 'entries/contentsIndex';
@import 'entries/printingMethodsIndex';
@import 'entries/themesIndex';

html {
  &.live-preview {
    .edit-button {
      display: none;
    }
  }
}

// Fix for iOS
.body {
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  &.no-scroll {
    overflow: hidden;
  }
}

.row {
  @include gutter(padding-left);
  @include gutter(padding-right);

  .row {
    @include gutter(margin-left, -.5);
    @include gutter(margin-right, -.5);
  }

  &.nested {
    @include gutter(padding-left, 0);
    @include gutter(padding-right, 0);

    .row {
      @include gutter(margin-left, 0);
      @include gutter(margin-right, 0);
    }
  }
}

// ScrollMagic
.scrollmagic {
  .c-header-advice-content,
  .c-header-logo,
  .c-header-links-desktop > li > a,
  .b-text,
  .b-gallery,
  .b-call-to-action,
  .b-list .b-list-item,
  .b-navigation,
  .b-table,
  .b-button,
  .b-themes-teaser .b-themes-teaser-entry,
  .b-icon .c-icon,
  .b-themes-teaser .c-icon,
  .b-contents-teaser .c-icon,
  .b-printing-methods-teaser .c-icon,
  .c-sticker,
  // .c-zellenkur,
  .c-footer {
    visibility: hidden;

    &.animated-visible {
      visibility: visible;
    }
  }
}

// ScrollReveal
.scrollreveal {
    // .sc-wrapper,
    // #header .dropdown-menu-wrapper > .dropdown.menu > li > a {
    //     visibility: hidden;
    // }
}

// Buttons
.button {
  padding: (.75 * $global-padding) (1.5 * $global-padding);
  font-size: 1.125rem;
  border-color: $primary;
  color: $primary;
  text-transform: uppercase;

  &:hover,
  &:focus {
    border-color: $primary;
    background-color: $primary;
    color: $white;
  }

  &:active {
    background-color: lighten($primary, 10%);
    border-color: lighten($primary, 10%);
    color: $white;
  }

  &.success {
    &:hover,
    &:focus {
      border-color: $success;
      color: inherit;
    }

    &:active {
      background-color: $success;
    }
  }

  &.alert {
    &:hover,
    &:focus {
      border-color: $alert;
      color: inherit;
    }

    &:active {
      background-color: $alert;
    }
  }

  &.secondary {
    &:hover,
    &:focus {
      border-color: $secondary;
      color: inherit;
    }

    &:active {
      background-color: $secondary;
    }
  }
}

.button-wrapper {
  a {
    @extend .button;
  }
}

a,
.button-wrapper a {
  @include transition;
}

.edit-button {
  @extend .button;
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: .75 * $global-padding;
  font-size: $small-font-size;
  z-index: 11;

  &,
  &:hover,
  &:focus,
  &:active {
    background-color: $white;
    border-color: $medium-gray;
    border-left: 0;
    border-bottom: 0;
  }

  &:hover,
  &:focus {
    color: $primary;
  }

  &:active {
    background-color: $primary;
    border-color: $primary;
    color: $white;
  }
}

.b-button a,
.button-wrapper a,
a.button {
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.highlight {
  color: $primary;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.25;
  color: $primary;

  + .subheading {
    margin-top: -.25rem;
  }
}

.subheading {
  font-size: 1.125rem;
  color: $dark-text;

  @include breakpoint(medium) {
    font-size: 1.25rem;
  }
}

.large-text {
  font-size: $large-font-size;
}

pre {
  margin-bottom: $global-margin;
}

// Debugging
// .row {
//   outline: 1px solid red;

//   .row {
//     outline-color: green;

//     .row {
//       outline-color: blue;
//     }
//   }
// }

// Spacing
.spacing {
  $from: 0;
  $to: 3;

  &-top {
    @for $i from $from through $to {
      &-#{$i} {
        @include gutter(margin-top, $i);
      }
    }
  }

  &-bottom {
    @for $i from $from through $to {
      &-#{$i} {
        @include gutter(margin-bottom, $i);
      }
    }
  }
}

.main .row {
  &:first-of-type > .column:first-of-type > {
    .b-hero {
      @include gutter(margin-top, -1);
    }
  }
}
