$b-gallery: 'b-gallery';
$b-gallery-height: $group-height;

.#{$b-gallery} {
  @include gutter(margin-bottom);
  position: relative;
  overflow: hidden;

  .vide,
  .iframe,
  .image,
  .object-fit-wrapper,
  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide {
    height: 100%;
    position: relative;
    overflow: hidden;
    z-index: 0;
  }

  .c-slider,
  .vide-wrapper {
    height: $b-gallery-height;
  }

  .iframe {
    display: block;
    width: 100%;
    height: 100%;
    border: 0;
  }

  .object-fit {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;

    &-wrapper {
      position: relative;
      min-height: $b-gallery-height;
    }
  }

  // Link
  &--link {
    display: block;

    .slick-prev,
    .slick-next {
      display: none !important;
    }
  }

  // Overlay text
  .overlay-text {
    @include transition;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 2 * $global-padding;
  }

  // Teaser
  &--teaser {
    .overlay-text {
      background-color: transparentize($black, .25);
      color: $white;
      top: auto;
      left: 0;
      bottom: 0;
      z-index: 1;
      transform: none;
      width: auto;
      padding-right: 3 * $global-padding;

      h1, h2, h3, h4, h5, h6 {
        margin-bottom: .25 * $global-margin;
      }
    }

    &.#{$b-gallery} {
      &::before {
        @include transition;
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
      }

      &:hover,
      &:focus {
        .overlay-text {
          padding-bottom: 3 * $global-padding;
        }
      }

      &:active {
        .overlay-text {
          background-color: transparentize($primary, .25);
        }
      }
    }
  }

  // Whole media
  &--whole-media {
    .object-fit {
      background-size: contain;
    }
  }

  // Spacing
  &.spacing {
    &-1 {
      .slick-slide {
        padding-left: $global-padding;
        padding-right: $global-padding;
      }
    }

    &-2 {
      .slick-slide {
        padding-left: 2 * $global-padding;
        padding-right: 2 * $global-padding;
      }
    }

    &-3 {
      .slick-slide {
        padding-left: 3 * $global-padding;
        padding-right: 3 * $global-padding;
      }
    }
  }
}
