.b-call-to-action {
  @include gutter(margin-bottom);
  @include gutter(padding, 2);
  font-size: 1.25rem;
  background-color: $primary;
  color: $white;
  text-align: center;

  h1, h2, h3, h4, h5, h6 {
    color: inherit;
  }

  .b-button {
    display: inline-block;
    margin: $global-margin;

    .button {
      text-transform: none;
      font-size: inherit;
      font-weight: $global-weight-bold;
      border-color: inherit;
      color: inherit;
      cursor: auto;

      &[href] {
        cursor: pointer;

        &:hover,
        &:focus {
          background-color: $white;
          color: $primary;
        }

        &:active {
          background-color: transparentize($white, .25);
        }
      }
    }
  }
}
