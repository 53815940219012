.c-footer {
  @include gutter(margin-top, -1);
  // @include gutter(margin-bottom, 2);
  overflow-x: hidden;

  &-content {
    // @include gutter(margin-bottom);
    background-color: $very-dark-gray;
    color: $white;
  }

  &-copyright {
    padding-top: .5 * $global-padding;
    padding-bottom: .5 * $global-padding;
    font-size: $small-font-size;
    background-color: lighten($very-dark-gray, 10%);
    color: $medium-gray;
  }

  .b-text,
  .b-navigation {
    a {
      color: inherit;

      &,
      &:hover,
      &:focus {
        text-decoration: none;
      }

      &:hover,
      &:focus {
        color: $very-light-gray;
      }

      &:active {
        background-color: $primary;
        color: $white;
      }
    }
  }

  .b-navigation {
    text-transform: uppercase;
  }

  .c-sticker {
    &__layer1 {
      font-size: $small-font-size;
      background-color: transparent;
      border-color: $white;
      color: $white;
    }
  }

  .c-zellenkur {
    margin-left: auto;

    &__svg {
      fill: $medium-gray;
    }
  }
}
