.b-table {
  @include gutter(margin-bottom);

  &--auto-width {
    @include breakpoint(medium) {
      table {
        width: auto;
      }
    }
  }

  table {
    td {
      &.head {
        display: none;
      }
    }
  }

  @include breakpoint(small only) {
    table {
      @include table-stack(false);

      tr {
        margin-top: .5 * $global-margin;
        margin-bottom: .5 * $global-margin;

        &:first-of-type {
          margin-top: 0;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      td {
        &:empty {
          &::after {
            content: '\00a0';
          }
        }

        &.head {
          background-color: $table-head-background;
          color: $table-head-font-color;
          font-weight: $global-weight-bold;

          &,
          & + td {
            display: inline-block;
            width: 50%;
          }

          & + td {
            background-color: $table-background;
          }
        }
      }
    }
  }
}
