$c-sticker: 'c-sticker';
$c-sticker-font-size: 1.25rem;

.#{$c-sticker} {
  @include gutter(margin-bottom);
  position: absolute;
  z-index: 1;
  width: 10rem;
  // height: 10rem;

  &--left {
    top: 0;
    left: 0;
  }

  &--right {
    top: 0;
    right: 0;
  }

  &--drop-left {
    bottom: 0;
    left: 0;
  }

  &--drop-right {
    bottom: 0;
    right: 0;
  }

  &--inline {
    position: static;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
  }

  &--even {
    .#{$c-sticker} {
      &__layer2 {
        transform: translate(-50%, -50%);
      }
    }
  }

  &[href] {
    .#{$c-sticker}__layer1 {
      &:hover,
      &:focus {
        background-color: $white;
        color: $dark-text;
      }

      &:active {
        background-color: transparentize($white, .25);
      }
    }
  }

  &__layer1 {
    position: relative;
    display: block;
    padding-bottom: 100%;
    overflow: hidden;
    border-radius: 50%;
    font-size: $c-sticker-font-size;
    background-color: transparentize($white, .25);
    border: 5px dotted $dark-text;
    color: $dark-text;
  }

  &__layer2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(5deg);
    width: 100%;
    text-align: center;
    padding: .5 * $global-padding;

    div,
    p {
      margin: 0;
      padding: 0;
      line-height: 1.25;
    }

    .large-text {
      font-size: 2em;
    }
  }
}
