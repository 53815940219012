.b-list {
  @include gutter(margin-bottom);
  font-size: 1.25rem;
  color: $dark-text;

  &-main {
    list-style-type: none;
  }

  &-item {
    position: relative;
    display: block;
    margin-bottom: .5 * $global-margin;
  }

  .value {
    position: relative;
    display: block;
    margin-left: 2.5rem;
  }

  .icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 2rem;
    height: 2rem;
    display: block;
    border: .0625rem solid $primary;
    border-radius: 50%;
    color: $primary;
    text-align: center;

    &::before {
      @extend .fi::before;
    }
  }

  .icon-check::before {
    content: $font-Flaticon-check;
  }

  .icon-email::before {
    content: $font-Flaticon-email;
  }

  .icon-phone::before {
    content: $font-Flaticon-phone;
  }

  .icon-none {
    display: none;

    + .value {
      margin-left: 0;
    }
  }
}
