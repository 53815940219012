$c-header-height: 5.25rem;
$c-header-logo-width: 15rem;
$c-header-logo-height: 1.25rem;
$c-header-links-space: .5 * $global-padding;
$c-header-links-padding: $c-header-links-space (2 * $c-header-links-space);

@mixin c-header-buttons() {
  padding: $c-header-links-padding;
  background-color: $very-light-gray;
  line-height: inherit;

  &:hover,
  &:focus {
    color: $primary;
  }

  &:active {
    background-color: $primary;
    color: $white;
  }
}

.c-header {
  @include gutter(margin-bottom);
  @include transition;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 111;
  background-color: $white;

  // &.headroom--bottom {
  //   &.headroom--not-top {
  //     .c-header-advice {
  //       display: none;
  //     }
  //   }
  // }

  .c-header-content {
    position: relative;
    z-index: 0;
    min-height: $c-header-height;
    background-color: $white;
  }

  .c-header-advice {
    @include full-width;
    font-size: $small-font-size;
    color: $white;
    background-color: $very-dark-gray;

    &-content {
      padding-top: .375rem;
      padding-bottom: .375rem;

      @include breakpoint(medium) {
        padding-left: $c-header-links-space;
        padding-right: $c-header-links-space;
      }
    }

    * {
      color: inherit;
      margin-bottom: 0;
    }
  }

  .c-header-logo {
    @include transition;
    position: absolute;
    top: .5 * ($c-header-height - $c-header-logo-height - 2 * $global-padding);
    z-index: 1;
    padding: $global-padding;
    padding-left: .25 * $global-padding;

    &,
    img {
      display: block;
    }

    img {
      width: auto;
      height: $c-header-logo-height;
    }
  }

  .c-header-links-mobile,
  .c-header-links-desktop {
    @include transition;
    position: relative;
    display: none;
    margin: 0;
    text-transform: uppercase;
    // font-weight: $global-weight-bold;

    li {
      vertical-align: middle;

      &.active {
        > a {
          // background-color: $primary;
          color: $primary;

          &:active {
            color: $white;
          }
        }
      }

      &.dropdownOpen {
        ul {
          min-width: 10rem;
          max-width: 20rem;
          background-color: $white;
          // box-shadow: 0 .0625rem 0 transparentize($light-gray, .5);

          li.active > a {
            color: $primary;

            &:active {
              color: $white;
            }
          }

          a {
            color: inherit;

            &:hover,
            &:focus {
              color: $primary;
            }

            &:active {
              color: $white;
            }
          }
        }
      }
    }

    a,
    span {
      white-space: normal;
      overflow: hidden;
    }

    a {
      padding: $c-header-links-space;
      color: $black;
      text-decoration: none;

      &:hover,
      &:focus {
        color: $primary;
      }

      &:active {
        background-color: $primary;
        color: $white;
      }
    }
  }

  .c-header-links-mobile {
    padding-top: $c-header-height;
    padding-bottom: $global-padding;

    ul {
      display: none;

      &.open {
        display: block;
      }
    }

    li,
    a {
      display: block;
    }

    li {
      position: relative;

      &.link-homepage {
        display: none;
      }

      &.dropdownOpen {
        ul {
          position: static;
          display: block;
          max-width: none;

          ul {
            padding-left: $global-margin;
          }
        }
      }
    }

    .show-children {
      @include c-header-buttons;
      position: absolute;
      top: 0;
      right: 0;

      &::before {
        @include transition;
        @extend .fi::before;
        content: $font-Flaticon-arrow-right;
      }

      &.open {
        &::before {
          transform: rotate(90deg);
        }
      }
    }
  }

  .show-links {
    @include c-header-buttons;
    @include gutter(right);
    position: absolute;
    top: .5 * ($c-header-height - 2.5rem);
    z-index: 1;

    &::before {
      @extend .fi::before;
      @include transition(.5s);
      content: $font-Flaticon-menu;
    }
  }

  // On open
  &.open {
    top: 0;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    margin: 0;
    padding: 0;
    background-color: transparentize($black, .25);

    .c-header-advice {
      display: none;
    }

    .c-header-links-mobile {
      display: block;
    }

    .show-links {
      &::before {
        content: $font-Flaticon-close;
        transform: rotate(180deg);
      }
    }
  }

  // Desktop
  @include breakpoint(medium) {
    &,
    &.open {
      .c-header-content {
        min-height: 0;
        height: $c-header-height;
      }

      .c-header-logo {
        @include gutter(right);
        padding: $global-padding;

        &:hover,
        &:focus {
          background-color: $very-light-gray;
        }

        &:active {
          background-color: darken($very-light-gray, 5%);
        }
      }

      .c-header-links-mobile,
      .show-links {
        display: none;
      }

      .c-header-links-desktop {
        top: 50%;
        transform: translateY(-50%);
        display: block;
        width: calc(100% - #{$c-header-logo-width});

        li {
          &.link-homepage {
            a {
              width: 1.75rem;
              height: 1.75rem;
              margin-right: $global-margin;
              background-image: url(/foundation/dist/assets/img/home.png);
              background-repeat: no-repeat;
              background-size: contain;
              background-position: 50% 50%;
              color: transparent;
              font-size: 0;

              &:hover,
              &:focus,
              &:active {
                background-color: transparent;
              }
            }
          }
        }
      }
    }
  }
}
