.b-contents-teaser {
  @include gutter(margin-bottom);
  @include gutter(padding-top, 3);
  @include gutter(padding-bottom, 3);

  // All
  &-all {
    @include gutter(margin-top, 2);
    text-align: center;

    .button {
      @include gutter(margin-bottom, 0);
    }
  }

  .b-text {
    @include gutter(margin-bottom, 3);
  }

  .c-icon {
    &,
    &:hover,
    &:focus {
      color: $primary;
    }
  }

  // Entry
  &-entry {
    @include gutter(margin-bottom, 2);
  }
}
