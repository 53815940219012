$b-hero: 'b-hero';
$b-hero-height: 30rem;
$b-hero-outside: $light-blue;
$b-hero-inside: $very-light-gray;
$b-hero-sticker-offset: 1rem;

.b-hero {
  @include full-width;
  position: relative;
  background-color: $b-hero-inside;
  padding-bottom: $global-padding;
  overflow-y: hidden;
  // background-image: linear-gradient(
  //   $b-hero-outside,
  //   $b-hero-inside 33%,
  //   $b-hero-inside 66%,
  //   $b-hero-outside
  // );

  .button-wrapper a {
    border-width: .2rem;
    color: $black;
    padding-left: $global-padding;
    padding-right: 4 * $global-padding;

    &:hover,
    &:focus,
    &:active {
      color: $white;
    }
  }

  &--dark {
    .button-wrapper a {
      color: $white;

      &:hover,
      &:focus,
      &:active {
        color: $black;
      }
    }

    .b-text {
      color: $white;

      .subheading {
        color: inherit;
        // opacity: .5;
      }
    }
  }

  &__main {
    position: relative;
    height: 100%;
  }

  &__content {
    margin-top: $b-hero-height;
    padding-top: $global-padding;
    // font-weight: $global-weight-bold;

    h1, h2, h3, h4, h5, h6 {
      // font-weight: inherit;
      font-weight: $global-weight-bold;
    }

    a {
      color: inherit;
      text-decoration: underline;
    }

    .button {
      text-decoration: none;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: -2rem;
    right: -2rem;
    bottom: 0;
    height: $b-hero-height;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% 50%;

    @include breakpoint($global-width) {
      left: -1rem;
      right: -1rem;
    }
  }

  .c-sticker {
    position: static;
    display: inline-block;

    &--left {
      top: $b-hero-sticker-offset;
      left: -$b-hero-sticker-offset;
    }

    &--right {
      top: $b-hero-sticker-offset;
      right: -$b-hero-sticker-offset;
    }

    &--drop-left {
      bottom: $b-hero-sticker-offset;
      left: -$b-hero-sticker-offset;
    }

    &--drop-right {
      bottom: $b-hero-sticker-offset;
      right: -$b-hero-sticker-offset;
    }

    @include breakpoint($global-width) {
      &--left {
        left: -2 * $b-hero-sticker-offset;
      }

      &--right {
        right: -2 * $b-hero-sticker-offset;
      }

      &--drop-left {
        left: -2 * $b-hero-sticker-offset;
      }

      &--drop-right {
        right: -2 * $b-hero-sticker-offset;
      }
    }
  }

  @include breakpoint(medium) {
    height: $b-hero-height;

    &--inverse {
      .#{$b-hero} {
        &__content {
          left: auto;
          right: 0;
          padding-left: 0;
          padding-right: $global-padding;
        }

        &__image {
          background-position: 0% 50%;
        }
      }
    }

    &__content {
      margin-top: 0;
      padding-top: 0;
      padding-left: $global-padding;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 50%;
    }

    .c-sticker {
      position: absolute;
      display: block;
    }
  }
}
