$b-chronicle-line-width: .25rem;
$b-chronicle-spacing: $global-padding;

.b-chronicle {
  @include gutter(margin-top);
  @include gutter(margin-bottom);
  position: relative;

  &::before {
    @include gutter(margin-top, -1);
    @include gutter(margin-bottom, -1);
    content: '';
    position: absolute;
    width: $b-chronicle-line-width;
    top: 0;
    bottom: 0;
    background-color: $light-gray;
  }

  &-entry {
    position: relative;
    margin-bottom: 2 * $b-chronicle-spacing;
    padding-left: $b-chronicle-spacing;

    &::before {
      content: '';
      position: absolute;
      top: 1/2 * (1rem * 1.5);
      transform: translateY(-50%);
      left: -1 * $b-chronicle-line-width;
      width: 3 * $b-chronicle-line-width;
      height: 3 * $b-chronicle-line-width;
      border-radius: 50%;
      background-color: $primary;
    }

    &-title {
      text-transform: uppercase;
      color: $primary;
    }

    &-description {
      margin-bottom: $b-chronicle-spacing;
    }

    &-image {
      max-width: 20rem;

      &,
      img {
        display: inline-block;
      }
    }
  }

  @include breakpoint(medium) {
    &::before {
      left: 50%;
      transform: translateX(-50%);
    }

    &-entry {
      width: 50%;
      padding-left: 3 * $b-chronicle-spacing;
      padding-right: $b-chronicle-spacing;
      text-align: right;

      &::before {
        left: auto;
        right: (-1/2 * $b-chronicle-spacing + 1/2 * $b-chronicle-line-width);
      }

      &:nth-of-type(even) {
        left: 50%;
        padding-left: $b-chronicle-spacing;
        padding-right: 3 * $b-chronicle-spacing;
        text-align: left;

        &::before {
          left: (-1/2 * $b-chronicle-spacing + 1/2 * $b-chronicle-line-width);
          right: auto;
        }
      }
    }
  }
}
