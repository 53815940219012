.b-tabs {
  @include gutter(margin-bottom);
  @include gutter(padding, 2);
  position: relative;
  background-color: $light-gray;

  .column {
    overflow: hidden;
  }

  &-text {
    @include gutter(margin-bottom, 2);
  }

  &-entries {
    .tabs {
      margin-bottom: $global-margin;
      margin-right: 2 * $global-margin;
      font-size: 1.25rem;
      text-transform: uppercase;

      &-title {
        margin-top: .25 * $global-margin;
        margin-bottom: .25 * $global-margin;

        &:hover,
        &:focus {
          cursor: pointer;
          color: $primary;
        }

        &:first-of-type {
          margin-top: 0;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        &.is-active {
          color: $primary;

          a {
            border-bottom-color: transparent;
          }
        }

        a {
          color: inherit;
          display: inline-block;
          border-bottom: .0625rem solid $medium-gray;
        }
      }
    }

    &-text {
      margin-bottom: $global-margin;
    }

    &-images {
      .masonry-item {
        @include gutter(padding, .5);
      }
    }
  }
}
