$b-themes-teaser: 'b-themes-teaser';
$b-themes-teaser-entry: 'b-themes-teaser-entry';
$b-themes-teaser-gutter-factor: 1.5;

.b-themes-teaser {
  @include full-width;
  @include gutter(margin-bottom);
  @include gutter(padding-top, 3);
  @include gutter(padding-bottom, 3);
  background-color: $very-light-gray;

  // All
  &-all {
    @include gutter(margin-top, 2);
    text-align: center;

    .button {
      @include gutter(margin-bottom, 0);
    }
  }

  // Entry
  &-entry {
    @include gutter(margin-bottom);
    display: flex;

    // Content
    &-content {
      display: flex;
      flex: 1;
      overflow: hidden;
      background-color: $white;
    }

    // Row and column
    .row {
      display: flex;
      flex: 1;
    }

    @include breakpoint(medium) {
      .column {
        display: flex;
        flex: 1;
      }
    }

    // Left
    &-left {
      position: relative;
    }

    // Anchor
    &-anchor {
      &-image {
        display: inherit;
        flex: inherit;

        &:hover,
        &:focus {
          .#{$b-themes-teaser-entry} {
            &-image {
              img {
                transform: scale(1.5) translateY(15%);
              }
            }

            &-arrow {
              transform: scale(.9) translateY(-.75rem) rotate(10deg);
            }
          }
        }

        &:active {
          .#{$b-themes-teaser-entry} {
            &-image {
              img {
                transform: scale(1.5) translateY(20%);
              }
            }
          }
        }
      }

      &-title {
        display: inline-block;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }

    // Image
    &-image {
      @include gutter(padding);
      @include gutter(padding-top, 2);
      overflow: hidden;
      flex: 1;
      background-color: $dark-gray;
      background-image: linear-gradient($black, $dark-text);
      text-align: center;

      img {
        @include transition(.5s);
        display: inline-block;
        width: 100%;
        max-width: 10rem;
      }
    }

    // Text
    &-text {
      @include gutter(margin-bottom, 0);
      @include gutter(padding, $b-themes-teaser-gutter-factor);
      position: relative;
      flex: 1;
      font-size: $small-font-size;
    }

    // Title
    &-title {
      // font-weight: $global-weight-bold;
      text-transform: uppercase;
    }

    // Link
    &-link {
      @include gutter(margin-top, $b-themes-teaser-gutter-factor)

      .button {
        @include gutter(bottom, $b-themes-teaser-gutter-factor);
        position: absolute;
        margin-bottom: 0;
      }
    }

    // Arrow
    &-arrow {
      display: none;

      @include breakpoint(medium) {
        @include gutter(top, 1.5 * $b-themes-teaser-gutter-factor);
        @include gutter(right, -1);
        @include transition(.5s);
        position: absolute;
        // display: block;
        width: 65%;
      }

      @include breakpoint(large) {
        width: 45%;
      }

      svg {
        display: block;
        width: 100%;
        height: 100%;
        stroke: $primary;
      }
    }

    // Icon
    .c-icon {
      @include gutter(left);
      @include gutter(bottom, 1.5);
      position: absolute;
      width: 6rem;
      margin-bottom: 0;
    }
  }
}
