.b-navigation {
  ul {
    list-style-type: none;
    margin: 0;

    ul {
      margin-left: $global-margin;
    }
  }

  a {
    color: inherit;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
